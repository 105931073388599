<template>
  <div class="nk-block">
    <div class="card card-bordered card-stretch">
      <div class="card-inner-group">
        <div class="card-inner position-relative card-tools-toggle">
          <div class="card-title-group">
            <!-- Filtros -->
            <slot name="filters"></slot>
            <!-- Acciones -->
            <div class="card-tools mr-n1" v-if="config.show_tools">
              <ul class="btn-toolbar gx-1">
                <li>
                  <div class="toggle-wrap">
                    <a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
                    <div class="toggle-content" data-content="cardTools">
                      <ul class="btn-toolbar gx-1">
                        <li>
                          <div class="dropdown">
                            <a href="#" class="btn btn-trigger btn-icon dropdown-toggle" data-toggle="dropdown">
                              <em class="icon ni ni-setting"></em>
                            </a>
                            <div class="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                              <ul class="link-check">
                                <li><span>Mostrar</span></li>
                                <li v-for="per_page in options.per_page" :key="per_page" :class="{active: (per_page === config.per_page)}"><a href="#" @click.prevent="setConfig('per_page', per_page)">{{per_page}}</a></li>
                              </ul>
                              <ul class="link-check">
                                <li><span>Ordenar</span></li>
                                <li v-for="order in options.order" :key="order" :class="{active: (order === config.order)}"><a href="#" @click.prevent="setConfig('order', order)">{{order}}</a></li>
                              </ul>
                            </div>
                          </div><!-- .dropdown -->
                        </li><!-- li -->
                      </ul><!-- .btn-toolbar -->
                    </div><!-- .toggle-content -->
                  </div><!-- .toggle-wrap -->
                </li><!-- li -->
              </ul><!-- .btn-toolbar -->
            </div><!-- .card-tools -->
          </div><!-- .card-title-group -->
        </div><!-- .card-inner -->
        <div class="rows-container card-inner p-0 overflow-x-auto">
          <div class="nk-tb-list nk-tb-ulist is-compact">
            <div class="nk-tb-item nk-tb-head">
              <slot name="header"></slot>
            </div><!-- .nk-tb-item -->
            <div class="nk-tb-item" v-for="row in props.rows" :key="row.key">
              <slot name="row" :row="row"></slot>
            </div><!-- .nk-tb-item -->
          </div><!-- .nk-tb-list -->
          <div class="alert alert-gray text-center" v-if="!props.rows.length">
            No se encontraron resultados
          </div><!-- .nk-tb-item -->
        </div><!-- .card-inner -->
        <div class="card-inner overflow-auto">
          <ul class="pagination justify-content-center justify-content-md-start">
            <li class="page-item page-icon"><button type="button" class="btn page-link" :disabled="config.current_page === 1" @click.prevent="setConfig('current_page', 1)"><span aria-hidden="true">«</span></button></li>
            <li class="page-item page-icon"><button type="button" class="btn page-link" :disabled="config.current_page === 1" @click.prevent="setConfig('current_page', config.current_page - 1)"><span aria-hidden="true">‹</span></button></li>
            <li :class="{'page-item': true, 'active': (page === config.current_page)}" v-for="page in pagination.pages" :key="page"><button type="button" class="btn page-link" @click.prevent="setConfig('current_page', page)">{{page}}</button></li>
            <li class="page-item page-icon"><button type="button" class="btn page-link" :disabled="config.current_page === pagination.totalPages" @click.prevent="setConfig('current_page', config.current_page + 1)"><span aria-hidden="true">›</span></button></li>
            <li class="page-item page-icon"><button type="button" class="btn page-link" :disabled="config.current_page === pagination.totalPages" @click.prevent="setConfig('current_page', pagination.totalPages)"><span aria-hidden="true">»</span></button></li>
          </ul><!-- .pagination -->
        </div><!-- .card-inner -->
      </div><!-- .card-inner-group -->
    </div><!-- .card -->
  </div><!-- .nk-block -->
</template>

<script>
import { ref, reactive, watch, watchEffect, computed, onMounted } from 'vue';
import paginate from 'jw-paginate';
export default {
  name: "TableGrid",
  props: {
    filters: Array,
    rows: Array,
    total: Number,
    show_tools: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const options = ref({
      per_page: [10, 30, 50],
      order: ['ASC', 'DESC']
    });

    const config = reactive({
      current_page: 1,
      order: options.value.order[0],
      per_page: options.value.per_page[0],
      total: props.total,
      show_tools: props.show_tools
    });

    const filters = computed(() => props.filters.filter(_filter => _filter.value.length > 0) );

    const pagination = computed(() => {
      let _pagination = paginate(config.total, config.current_page, config.per_page, 5);
      validateCurrentPage();
      return  _pagination;
    });

    watch(props.filters, () => {
      config.current_page = 1;
      update();
    });

    watchEffect(() => {
      config.total = props.total;
    });

    function setConfig(option, value){
      switch (option) {
        case 'per_page':
          config.per_page = value;
          validateCurrentPage();
          break;
        case 'order':
          config.order = value;
          break;
        case 'current_page':
          config.current_page = value;
          break;
      }
      update();
    }

    function update(){
      emit('update', {config, filters: filters.value});
    }

    function validateCurrentPage(){
      if(pagination.value && pagination.value.totalPages < config.current_page && pagination.value.totalPages >= 1){
        config.current_page = pagination.value.totalPages;
      }
    }

    onMounted(() => {
      update();
    });

    return { props, options, config, pagination, setConfig };
  }
}
</script>

<style scoped>
  .page-icon .btn{
    font-size: 16px;
  }
  .rows-container {
    min-height: 250px;
  }
</style>